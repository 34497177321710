import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';

export const CardTable = ({ cards, selectionModel, setSelectionModel }) => {
    const columns = [
        { field: `name`, headerName: `Title`, width: 200 },
        { field: `reference`, headerName: `Reference`, width: 125 },
        { field: `type`, headerName: `Type`, width: 130 },
    ];

    return cards ? (
        <div style={{ height: `45em` }}>
            <DataGrid
                autoHeight={true}
                checkboxSelection
                columnBuffer={3}
                columns={columns}
                headerHeight={52}
                onSelectionModelChange={(newSelection) => {
                    setSelectionModel(newSelection);
                }}
                pageSize={10}
                rowHeight={48}
                rows={cards}
                scrollbarSize={15}
                selectionModel={selectionModel}
            />
        </div>
    ) : null;
}
