import * as React from "react";
import { merge } from "lodash";
import {Avatar} from "@material-ui/core";
import {useFile} from "../../common/hooks";
import {SvgIcon} from "../../common/SvgIcon";

const defaultImgProps = {
    loading: `lazy`,
    style: {
        objectFit:`contain`
    }
}

const TextIcon = ({altText}) => {
    return (
        <Avatar alt={altText} variant="square">
            {altText[0]}
        </Avatar>
    )
}

const ImageIcon = ({altText, fileId, imgProps}) => {
    const [fileUrl, setFileUrl] = React.useState(null);
    const { isFetching, data: result } = useFile(fileId);
    React.useEffect(() => {
        if (!isFetching && result && result.data) {
            setFileUrl(`${process.env.GATSBY_API_URL}${result.data.asset_url}?key=directus-small-contain`);
        }
    }, [result, isFetching]);

    return !fileUrl ? (
        <TextIcon altText={altText} />
    ) : (
        <Avatar alt={altText} imgProps={imgProps} src={fileUrl} variant="square" />
    )
}

export const SetIcon = ({altText, className, fileId, gameUrlTitle, imgProps, logoUrl, svgIcon}) => {
    let mergedImgProps = defaultImgProps;
    merge(mergedImgProps, imgProps);

    if (svgIcon) {
        return <SvgIcon alt={altText} className={className} gameUrlTitle={gameUrlTitle} imgProps={mergedImgProps} svgIcon={svgIcon} />
    }
    if (logoUrl) {
        return <Avatar alt={altText} imgProps={mergedImgProps} src={logoUrl} variant="square" />
    }
    return fileId ? (
            <ImageIcon altText={altText} fileId={fileId} imgProps={mergedImgProps} />
        ) : (
            <TextIcon altText={altText} />
    )
};
