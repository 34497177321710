/*
http://www.loc.gov/standards/iso639-2/php/code_list.php
ISO-639-1 : Alpha-2 (two letter) language code, e.g. en = English
ISO-639-2 : Alpha-3 language code, e.g. eng = English
 */

export const languages = {
    zh: {name: `Chinese`, countryCode: `CN`},
    "zh-CN": {name: `Chinese (Simplified)`, countryCode: `CN`},
    "zh-TW": {name: `Chinese (Traditional)`, countryCode: `TW`},
    cs: {name: `Czech`, countryCode: `CZ`},
    da: {name: `Danish`, countryCode: `DK`},
    nl: {name: `Dutch`, countryCode: `NL`},
    en: {name: `English`, countryCode: `GB`},
    fi: {name: `Finnish`, countryCode: `FI`},
    fr: {name: `French`, countryCode: `FR`},
    de: {name: `German`, countryCode: `DE`},
    el: {name: `Greek`, countryCode: `GR`},
    he: {name: `Hebrew`, countryCode: `IL`},
    hu: {name: `Hungarian`, countryCode: `HU`},
    it: {name: `Italian`, countryCode: `IT`},
    jp: {name: `Japanese`, countryCode: `JP`},
    ko: {name: `Korean`, countryCode: `KR`},
    no: {name: `Norwegian`, countryCode: `NO`},
    pl: {name: `Polish`, countryCode: `PL`},
    pt: {name: `Portuguese`, countryCode: `PT`},
    ru: {name: `Russian`, countryCode: `RU`},
    es: {name: `Spanish`, countryCode: `ES`},
    sv: {name: `Swedish`, countryCode: `SE`},
};

export const codesToNames = (langCodes) => {
    return langCodes.map(lang => languages[lang] ? languages[lang].name : lang);
}

export const languageCodes = Object.keys(languages);

