import * as React from 'react';
import {Link} from "gatsby";
import {ImageList, ImageListItem, ImageListItemBar} from "@material-ui/core";
import placeholderCard from "../../images/ui/placeholder-card.png";
import {CardRarity} from "./CardRarity";

export const CardImageList = ({ cards }) => {
    return (
        <ImageList className={`justify-center lg:justify-start`} cols={0} gap={16} rowHeight={`auto`}>
            {cards.map(card => (
                <ImageListItem cols={1} key={card.id}>
                    <Link to={`/card/${card.id}/${card.url_title}`}>
                        <CardImage card={card} />
                        <ImageListItemBar
                            subtitle={<CardRarity rarityNum={(card.rarity && card.rarity.id) || 0} />}
                            title={card.subtitle ? `${card.name} (${card.subtitle})` : card.name}
                        />
                    </Link>
                </ImageListItem>
            ))}
        </ImageList>
    )
};

const CardImage = ({card}) => {
    if (card.image_url) {
        return <img alt={card.name} height="300" loading="lazy" src={card.image_url.replace(`_hires`,``)} style={{height:`18rem`}} width="215" />
    }
    if (card.image) {
        return (
            <img alt={card.name} height="300" loading="lazy" src={`${process.env.GATSBY_API_URL}${card.image.data.asset_url}?key=card-medium`} width="215" />
        )
    }
    return (
        <img alt={card.name} height="300" loading="lazy" src={placeholderCard} style={{height:`18rem`}} width="215" />
    )
}
