import * as React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import {Breadcrumbs, Typography} from "@material-ui/core";

export const BreadcrumbNav = ({ crumbs }) => {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {crumbs.map((crumb, index) => {
                return (index === crumbs.length - 1) ? (
                    <Typography color="textPrimary" key={index}>{crumb}</Typography>
                ) : (
                    <Link key={index} to={`../`.repeat(crumbs.length - index - 1)+`/`}>
                        {crumb}
                    </Link>
                )
            })}
        </Breadcrumbs>
    )
}

BreadcrumbNav.propTypes = {
    crumbs: PropTypes.arrayOf(PropTypes.string)
}
